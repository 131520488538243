<template>
  <BaseForm
    :id="id"
    ref="form"
    :change-cb="changeCb"
    :close="closeCard"
    :disabled="disabled"
    :form-type="formType"
    :layout="layout"
    :loading="loading"
    :row="row"
    :select-models="selectModels"
  >
    <template #bottom>
      <v-snackbar
        v-model="displayerr"
        color="error"
        :multi-line="isModeMultiLine"
        :timeout="0"
        :vertical="isModeVertical"
        class="errormsg"
        type="error"
      >
        {{ errmsg }}
        <v-btn
          id="lead-source-form-button-close"
          dark
          flat
          @click="displayerr = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-btn
        v-if="isModeView"
        id="lead-source-form-button-back-to-table"
        class="btn-secondaryaction"
        @click="backToTable"
      >
        All Lead Sources
      </v-btn>
      <v-btn
        v-else
        id="lead-source-form-button-cancel"
        class="btn-secondaryaction"
        @click="handleCancel"
      >
        Cancel
      </v-btn>

      <v-btn
        v-if="isModeAdd"
        id="lead-source-form-button-add-lead-source"
        :disabled="loading"
        :loading="loading"
        class="btn-primaryaction"
        @click="submit"
      >
        Add Lead Source
      </v-btn>
      <div v-else>
        <div v-if="disabled">
          <v-btn
            id="lead-source-form-button-edit-lead-source"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Lead Source
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            id="lead-source-form-button-save-lead-source"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Lead Source
          </v-btn>
        </div>
      </div>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import leadSources from '@/services/leadSources'
import { authComputed } from '@/state/helpers'
import { isNotEmpty, isRequired } from '@/utils/validators'

export default {
  name: 'LeadSourceForm',
  components: {
    BaseForm,
  },
  metaInfo() {
    const meta = {
      title: '',
    }

    if (this.isModeEdit) {
      meta.title = `Edit: ${this.row.label}`
    } else if (this.isModeAdd) {
      meta.title = 'Add Lead Source'
    } else if (this.isModeView) {
      meta.title = `${this.row.label}`
    }

    return meta
  },
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      loading: false,
      disabled: true,
      selectModels: {},
      errmsg: '',
      displayerr: false,
      row: {},
      layout: [],
      formType: 'Lead Source',
    }
  },
  computed: {
    ...authComputed,
    isModeMultiLine() {
      return this.mode === 'multi-line'
    },
    isModeVertical() {
      return this.mode === 'vertical'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
  },
  updated() {
    if (this.row) {
      if (this.isModeEdit) {
        this.formType = `Edit: ${this.row.label}`
      } else if (this.isModeAdd) {
        this.formType = 'Add Lead Source'
      } else if (this.isModeView) {
        this.formType = this.row.label
      }
    }
  },
  async mounted() {
    if (['edit', 'add'].includes(this.mode)) {
      this.disabled = !this.disabled
    }

    if (!this.isModeAdd) {
      leadSources
        .getLeadSource(this.id)
        .then((data) => {
          this.row = data.data
        })
        .catch((error) => {
          throw new Error(error)
        })
    }

    this.layout = [
      {
        id: 1,
        fields: [
          {
            key: 'label',
            label: 'Display Name',
            type: 'text',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Display Name Required',
                error: 'Display Name Required',
              }),
            ],
            tooltip:
              "A lead source's display name is the user-friendly name shown to users. You can change the display name at any time without breaking anything.",
          },
          {
            key: 'key',
            label: 'Internal Name',
            type: 'text',
            forceDisabled: !this.isModeAdd,
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Internal Name Required',
                error: 'Internal Name Required',
              }),
            ],
            tooltip:
              "A lead source's internal name identifies it within the system and will never be shown to the user. Once it is set it cannot be changed.",
          },
          {
            key: 'description',
            label: 'Description',
            type: 'text-box',
          },
        ],
      },
    ]
  },
  methods: {
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
    },
    handleCancel() {
      if (this.isModeAdd) {
        this.$router.push('/lead-sources')
      } else {
        this.disabled = true

        this.$router.push(`/lead-sources/view/${this.id}`)
      }
    },
    submit() {
      this.loading = true
      const test = this.$refs.form.validateAll()
      if (test.length > 0) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be Required. or are invalid.',
          true
        )
        return null
      }

      if (this.isModeAdd) {
        this.row.companyId = this.currentUser.companyId

        return leadSources
          .createLeadSource({
            payload: this.row,
          })
          .then(() => {
            this.loading = false
            this.disabled = !this.disabled
            this.$router.push('/lead-sources')
          })
          .catch((error) => {
            this.loading = false
            this.disabled = !this.disabled
            this.displayWarning = true
            this.warningMessage = error
          })
      }
      return leadSources
        .updateLeadSource({
          id: this.id,
          payload: this.row,
        })
        .then(() => {
          this.loading = false
          this.disabled = !this.disabled
          this.$router.push(`/lead-sources/view/${this.id}`)
          this.$store.dispatch('app/showAlert', {
            message: 'Lead source saved.',
            type: 'success',
          })
        })
        .catch((error) => {
          this.loading = false
          this.disabled = !this.disabled
          this.displayWarning = true
          this.warningMessage = error
        })
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.$router.push(`/lead-sources/edit/${this.id}`)
      }

      this.disabled = !this.disabled
    },
    backToTable() {
      this.$router.push('/lead-sources')
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  flex-direction: column;
  background-color: $white;

  .card-title {
    display: flex;
    justify-content: space-between;

    .title {
      margin-bottom: 20px;
      font-family: 'Lato', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.03em;
    }
  }

  .subticket-items {
    margin: 0 0 0 25px;

    .subticket {
      .subticket-header {
        font-weight: bold;
      }
    }
  }

  .new-subticket-box {
    margin: 25px;
  }

  .row-block-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .row-block {
      max-width: 48%;
    }
  }
}
</style>
